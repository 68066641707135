import React from "react";
import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";

const Titlebar = ({ title, isBg }) => {
  return (
    <section
      id="all-blogs"
      className={`all-blogs hero__padding overflow-hidden position-relative ${isBg === "yes" ? "bg-one": "" }`}
    >
      <div className="container">
        <div className="row">
          <div>
            <h1 className="display-3">
              {title}
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Titlebar;
