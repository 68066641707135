/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link as PageLink } from "react-router-dom";
import data from "../data/achieve.json";
import { HashLink } from "react-router-hash-link";

const Resources = ({ isBg }) => {
  const { achivev3 } = data;
  return (
    <section
      id="benefits"
      className={`achieve3 section-padding  ${isBg === "yes" ? "bg-one" : ""}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 my-auto">
            <div className="row">
              <div className="col-md-11 offset-1 ms-0">
                <div className="section-title-left text-center text-md-start">
                  <h2 className="display-6">{achivev3.title}</h2>
                  <p className="text-muted mb-5 fs-5">{achivev3.description}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-4">
            <div className="row">
              <div
                className="col-md-6 mb-4 mb-md-0"
                data-aos="fade-up"
                data-aos-duration="0"
                data-aos-delay="0"

              >
                <PageLink to="/downloads#worksheets">
                  <div className="achieve3__item h-100 translateEffect1">
                    <div className="achieve__icon m-20px-b">
                      <img
                        className="img-fluid"
                        src={achivev3.achieveItem1.icon}
                        alt="icon"
                        width="40"
                        height="40"
                      />
                    </div>
                    <h3 className="m-15px-b">{achivev3.achieveItem1.title}</h3>
                    <p>{achivev3.achieveItem1.description}</p>
                  </div>
                </PageLink>
              </div>

              <div
                className="col-md-6 mb-4 mb-md-0"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"

              >
                <PageLink to="/downloads#checklists">
                  <div className="achieve3__item h-100 translateEffect1">
                    <div className="achieve__icon m-20px-b">
                      <img
                        className="img-fluid"
                        src={achivev3.achieveItem2.icon}
                        alt="icon"
                        width="40"
                        height="40"
                        color="#fff"
                      />
                      <i class="icofont-content"></i>
                    </div>
                    <h3 className="m-15px-b">{achivev3.achieveItem2.title}</h3>
                    <p>{achivev3.achieveItem2.description}</p>
                  </div>
                </PageLink>
              </div>
            </div>
          </div>
        </div>
        <div className="row">

          <div
            className="col-md-6 col-lg-3 mb-4 mb-lg-0"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="100"
          >
            <PageLink to="/downloads#quickreference">
              <div className="achieve3__item h-100 translateEffect1">
                <div className="achieve__icon m-20px-b">
                  <img
                    className="img-fluid"
                    src={achivev3.achieveItem3.icon}
                    alt="icon"
                    width="40"
                    height="40"
                  />
                </div>
                <h3 className="m-15px-b">{achivev3.achieveItem3.title}</h3>
                <p>{achivev3.achieveItem3.description}</p>
              </div>
            </PageLink>
          </div>

          <div
            className="col-md-6 col-lg-3 mb-4 mb-lg-0"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="200"
          >
            <PageLink to="/downloads#lessonplans">
              <div className="achieve3__item h-100 translateEffect1">
                <div className="achieve__icon m-20px-b">
                  <img
                    className="img-fluid"
                    src={achivev3.achieveItem4.icon}
                    alt="icon"
                    width="40"
                    height="40"
                  />
                </div>
                <h3 className="m-15px-b">{achivev3.achieveItem4.title}</h3>
                <p>{achivev3.achieveItem4.description}</p>
              </div>
            </PageLink>
          </div>

          <div
            className="col-md-6 col-lg-3 mb-4 mb-md-0"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            <HashLink to="/downloads#slidedecks">
              <div className="achieve3__item h-100 translateEffect1">
                <div className="achieve__icon m-20px-b">
                  <img
                    className="img-fluid"
                    src={achivev3.achieveItem5.icon}
                    alt="icon"
                    width="40"
                    height="40"
                  />
                </div>
                <h3 className="m-15px-b">{achivev3.achieveItem5.title}</h3>
                <p>{achivev3.achieveItem5.description}</p>
              </div>
            </HashLink>
          </div>

          <div
            className="col-md-6 col-lg-3 mb-4 mb-md-0"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="400"
          >
            <PageLink to={{
              pathname: "/downloads",
              hash: "#writingexercises",
            }}>
            <div className="achieve3__item h-100 translateEffect1">
              <div className="achieve__icon m-20px-b">
                <img
                  className="img-fluid"
                  src={achivev3.achieveItem6.icon}
                  alt="icon"
                  width="40"
                  height="40"
                />
              </div>
              <h3 className="m-15px-b">{achivev3.achieveItem6.title}</h3>
              <p>{achivev3.achieveItem6.description}</p>
            </div>
            </PageLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resources;
