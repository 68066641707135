
import React from "react";
import Header from "../global/header";
import Footer from "../global/footer";
import TitleBar from "../title-bar";
import {default as AllResources} from "../resourcesv2";

const Resources = ({ header, footer}) => {
  const { menuv3 } = footer;

  return (
    <>
      <Header header={header} />
      <TitleBar isBg="no" title="Free Downloads" />
      <AllResources isBg=""/>
      <Footer isBg="yes" menu={menuv3} />
    </>
  );
};

export default Resources;
