/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

// Define sections with subitems (subsections), each with its own markdown file
const sections = [
  {
    id: "overview",
    title: "Overview",
    subitems: [
      { id: "about-sub1", title: "About This Guide", markdownUrl: "/markdown/about.md" },
      { id: "about-sub2", title: "General Principles", markdownUrl: "/markdown/principles.md" }
    ],
  },
  {
    id: "guidelines",
    title: "Style Guidelines",
    subitems: [
      { id: "guidelines-sub1", title: "Be Consistent", markdownUrl: "/markdown/consistent.md" },
      { id: "guidelines-sub2", title: "Be Concise", markdownUrl: "/markdown/concise.md" },
      { id: "guidelines-sub3", title: "Focus on Your Reader", markdownUrl: "/markdown/reader-centric.md" },
      { id: "guidelines-sub4", title: "Let Form Follow Function", markdownUrl: "/markdown/about.md" },
      { id: "guidelines-sub6", title: "Use the Active Voice", markdownUrl: "/markdown/active-voice.md" },
      { id: "guidelines-sub7", title: "Use Person-First Language", markdownUrl: "/markdown/about.md" },
      { id: "guidelines-sub8", title: "Use Plain Language", markdownUrl: "/markdown/plain-language.md" },
    ],
  },
  {
    id: "grammar",
    title: "Grammar & Usage",
    subitems: [
      { id: "grammar-sub1", title: "Abbreviations & Acronyms", markdownUrl: "/markdown/abbreviations.md" },
      { id: "grammar-sub2", title: "Capitalization", markdownUrl: "/markdown/capitalization.md" },
      { id: "grammar-sub3", title: "Numbers", markdownUrl: "/markdown/numbers.md" },
      { id: "grammar-sub4", title: "Spaces", markdownUrl: "/markdown/spaces.md" },
      { id: "grammar-sub5", title: "Text Formatting", markdownUrl: "/markdown/formatting.md" },
      { id: "grammar-sub6", title: "Trademarks & Brands", markdownUrl: "/markdown/trademark.md" },
      { id: "grammar-sub7", title: "Words", markdownUrl: "/markdown/words.md" },
    ],
  },
  {
    id: "punctuation",
    title: "Punctuation",
    subitems: [
      { id: "punctuation-sub1", title: "Ampersand (&)", markdownUrl: "/markdown/ampersand.md" },
      { id: "punctuation-sub2", title: "Bullets (•)", markdownUrl: "/markdown/bullets.md" },
      { id: "punctuation-sub3", title: "Colon (:)", markdownUrl: "/markdown/colon.md" },
      { id: "punctuation-sub4", title: "Comma (,)", markdownUrl: "/markdown/comma.md" },
      { id: "punctuation-sub5", title: "Dashes (—)", markdownUrl: "/markdown/dash.md" },
      { id: "punctuation-sub6", title: "Exclamation Point (!)", markdownUrl: "/markdown/exclamation-point.md" },
      { id: "punctuation-sub7", title: "Period (.)", markdownUrl: "/markdown/period.md" },
      { id: "punctuation-sub8", title: "Quotes (\"\")", markdownUrl: "/markdown/quotes.md" },
      { id: "punctuation-sub9", title: "Slash (/)", markdownUrl: "/markdown/slash.md" },

    ],
  },
];

const StyleGuide = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [visibleSection, setVisibleSection] = useState("about"); // Set default to "about" to make it visible on load
  const [hasScrolled, setHasScrolled] = useState(false); // Track if user has scrolled

  // State to store markdown content for sections and subitems
  const [sectionContents, setSectionContents] = useState({}); // Object to store the content for each section and subitem

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    const yOffset = -70; // Offset for a fixed header
    const y = section.getBoundingClientRect().top + window.scrollY + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  useEffect(() => {
    // Handle sticky sidebar
    const handleScroll = () => {
      const sidebar = document.querySelector(".styleguide-sidebar");
      if (sidebar) {
        const offsetTop = sidebar.offsetTop - 70;
        if (window.scrollY > offsetTop && window.scrollY > 415) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }

      // Detect scroll to trigger section visibility updates
      if (window.scrollY > 0) {
        setHasScrolled(true); // User has scrolled, no need to show the "About" section by default anymore
      } else {
        // If the user scrolls back to the top, set visible section to "about"
        setVisibleSection("about");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Dynamically fetch markdown content for each section and subitem
    const fetchMarkdownContent = async () => {
      const contentPromises = sections.map(async (section) => {
        const sectionResponse = await fetch(section.markdownUrl);
        const sectionText = await sectionResponse.text();

        const subitemPromises = section.subitems.map(async (subitem) => {
          const subitemResponse = await fetch(subitem.markdownUrl);
          const subitemText = await subitemResponse.text();
          return { id: subitem.id, content: subitemText };
        });

        const subitemContents = await Promise.all(subitemPromises);

        return {
          id: section.id,
          content: sectionText,
          subitems: subitemContents.reduce((acc, cur) => {
            acc[cur.id] = cur.content;
            return acc;
          }, {}),
        };
      });

      const contents = await Promise.all(contentPromises);
      const contentObject = contents.reduce((acc, cur) => {
        acc[cur.id] = { content: cur.content, subitems: cur.subitems };
        return acc;
      }, {});

      setSectionContents(contentObject);
    };

    fetchMarkdownContent();
  }, []);

  useEffect(() => {
    // Handle section visibility using IntersectionObserver
    const sectionElements = document.querySelectorAll("section");
    const observer = new IntersectionObserver(
      (entries) => {
        let activeSection = null;

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Mark the parent section as active even if the subitems are visible
            activeSection = entry.target.getAttribute("data-parent") || entry.target.getAttribute("id");
          }
        });

        if (activeSection) {
          setVisibleSection(activeSection);
        } else if (window.scrollY === 0) {
          // If scrolled back to the top, show "About" as the visible section
          setVisibleSection("about");
        }
      },
      { rootMargin: "-20% 0px -90% 0px", threshold: 0 }
    );

    sectionElements.forEach((section) => observer.observe(section));

    return () => {
      sectionElements.forEach((section) => observer.unobserve(section));
    };
  }, []);

  return (
    <div className="section-padding events">
      <div className="container style-guide-container">
        <div className="row">
          <div className="col-md-4 col-lg-3">
            {/* Sidebar */}
            <aside className={`styleguide-sidebar ${isSticky ? "sticky" : ""}`}>
              <nav>
                <ul className="styleguide-nav">
                  {sections.map((section) => (
                    <li key={section.id}>
                      <a className="styleguide-sidebar-heading" onClick={() => scrollToSection(section.id)}>
                        {section.title}
                      </a>
                      {/* Render subitems dynamically */}
                      <ul
                        style={{
                          display:
                            visibleSection === section.id || (section.id === "about" && visibleSection === "about")
                              ? "block"
                              : "none",
                        }}
                      >
                        {section.subitems.map((subitem) => (
                          <li key={subitem.id}>
                            <a onClick={() => scrollToSection(subitem.id)}>{subitem.title}</a>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </nav>
            </aside>
          </div>
          {/* Content */}
          <div className="col-md-9 styleguide-content">
            {sections.map((section) => (
              <section id={section.id} key={section.id}>
                <h1 className="styleguide-h1">{section.title}</h1>
                {/* Render subsections dynamically in the content */}
                {section.subitems.map((subitem) => (
                  <section id={subitem.id} key={subitem.id} data-parent={section.id}>
                    <h2>{subitem.title}</h2>
                    <ReactMarkdown>{sectionContents[section.id]?.subitems[subitem.id] || "Loading..."}</ReactMarkdown>
                  </section>
                ))}
              </section>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StyleGuide;