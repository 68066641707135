import React from "react";
import Footer from "../global/footer";
import Header from "../global/header";
import ConfirmDownloadHero from "../confirm-download-hero";

const ConfirmDownload = ({header, footer}) => {
  const {menu} = footer;

  return (
    <>
     <Header header={header} />
     <ConfirmDownloadHero isBg="yes" />
    <Footer  isBg="yes" menu={menu} />
    </>
  );
}

export default ConfirmDownload;
