import React from "react";
import { Link as PageLink } from "react-router-dom";


const ConfirmDownloadHero = ({ isBg }) => {
  return (
    // <!-- ========== Hero section start ========== -->
    <section
      id="hero"
      className={`hero hero__padding overflow-hidden position-relative ${isBg === "yes" ? "bg-one" : ""
        }`}
    >
      <div className="container" style={{ minHeight:"60vh"}}>
        <div className="row align-items-center">
          <div className="col-sm-12 md-m-30px-b m-0px-b text-center">
            <div className="hero__content position-relative">
              <h1 className="display-2 mb-4 text-capitalize">Check your inbox!</h1>
              <span className="mb-4" style={{fontSize:"1.2rem"}}>Your download is sent as an email attachment from noreply@policewriting.com. <br /> If you haven't received it within five minutes, check your spam folder.</span>

              <div className="row ">
                <div className="col-md-12 text-left mt-4" >
                  <PageLink to="/downloads" className="button buttom_primary">
                    <span>Return to Downloads Page</span>
                  </PageLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
};

export default ConfirmDownloadHero;