import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import data from "../data/hero.json";
import { mailchimpLink } from "../global";

//SUBSCRIBE FORM
function SubscribeForm({ status, message, onValidated }) {
  let email;
  const submit = (e) => {
    e.preventDefault();
    onValidated({
      EMAIL: email.value,
    });

  };

  return (
    <form>

      <div className="input-group">

        <input
          ref={(node) => (email = node)}
          type="email"
          required
          placeholder="Email Address"
        />
        <button type="submit" className="button button__primary" onClick={submit}>
          <span>Get Free Chapter</span>
        </button>
      </div>
      <div className="message col m-10px-t hero__subscribe__form__message">
        {status === "sending" && (
          <div className=" alert alert-warning">sending...</div>
        )}
        {status === "error" && (
          <div
            className="alert alert-danger"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </div>
      {status === "success" && (
        <div
          className="alert alert-success hero__subscribe__form__success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </form>

  );
}

const Hero = ({ isBg }) => {
  const { herov1 } = data;
  return (
    // <!-- ========== Hero section start ========== -->
    <section
      id="hero"
      className={`hero hero__padding overflow-hidden position-relative ${isBg === "yes" ? "bg-one" : ""
        }`}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 md-m-30px-b m-0px-b">
            <div className="hero__content position-relative">
              {/* <div className="badge-text mb-2 text-uppercase">
                {herov1.subtitle}
              </div> */}
              <h1 className="display-2 mb-4 text-capitalize">{herov1.title}</h1>
              {/* <p className="text-muted mb-2 fs-5">{herov1.description}</p> */}
              {/* <MailchimpSubscribe
                url={mailchimpLink.link}
                render={({ subscribe, status, message }) => (
                  <SubscribeForm
                    status={status}
                    message={message}
                    onValidated={(formData) => subscribe(formData)}
                  />

                )}

              /> */}
              <div className="row ">
                <div className="col-md-12 text-left mt-4" >
                  <a href="https://a.co/d/9Wslx6k" target="_blank" className="button buttom_primary">
                    <span>Buy the Book</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1 col-md-12 text-center">
            <div className="hero__images2">
              <img className="img-fluid" src={herov1.image} alt="" style={{ width: '413px', boxShadow: '20px 20px 25px 0px rgba(0,0,0,.4)' }} />
            </div>
          </div>
        </div>
      </div>
    </section>

    // <!-- ========== Hero section start ========== -->
  );
};

export default Hero;
