/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link as PageLink } from "react-router-dom";
import data from "../data/downloads.json";

const Resourcesv2 = ({ isBg }) => {
  const { worksheets } = data;
  const { checklists } = data;
  const { quickReference } = data;
  const { lessonPlans } = data;
  const { slideDecks } = data;
  const { writingExercises } = data;

  return (
    <section
      id="benefits"
      className={`achieve3 section-padding  ${isBg === "yes" ? "bg-one" : ""}`}
    >
    {/* Worksheets */}
    <div id="worksheets" className="container" style={{ marginBottom: '12em' }}>
        {/* Title */}
        <div className="row">
          <div className="col-lg-6 my-auto">
            <div className="row">
              <div className="col-md-11 offset-1 ms-0">
                <div className="section-title-left text-center text-md-start">
                  <h2 className="display-6">{worksheets.title}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Items  */}
        <div className="row">
          {worksheets.items?.map((data) => (
            <div
              className="col-md-6 col-lg-3 mb-4 mb-md-0"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <PageLink to={data.link}>
                <div className="achieve3__item h-100 translateEffect1">
                  <h3 className="m-15px-b">{data.title}</h3>
                  <p>{data.description}</p>
                  <img
                      src={data.preview}
                      className="img-fluid"
                      width="240"
                      border="1px solid"
                      background="#fff"
                    />
                </div>
              </PageLink>
            </div>
          ))}
        </div>
    </div>
     
    {/* Checklists */}
    <div className="container" style={{ marginBottom: '12em' }}>
        {/* Title */}
        <div className="row">
          <div className="col-lg-6 my-auto">
            <div className="row">
              <div className="col-md-11 offset-1 ms-0">
                <div className="section-title-left text-center text-md-start">
                  <h2 className="display-6">{checklists.title}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Items  */}
        <div className="row">
          {checklists.items?.map((data) => (
            <div
              className="col-md-6 col-lg-3 mb-4 mb-md-0"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <PageLink to={data.link}>
                <div className="achieve3__item h-100 translateEffect1">
                  <h3 className="m-15px-b">{data.title}</h3>
                  <p>{data.description}</p>
                  <img
                      src={data.preview}
                      className="img-fluid"
                      width="240"
                      border="1px solid"
                      background="#fff"
                    />
                </div>
              </PageLink>
            </div>
          ))}
        </div>
    </div>

    {/* Quick Reference */}
    <div className="container" style={{ marginBottom: '12em' }}>
        {/* Title */}
        <div className="row">
          <div className="col-lg-6 my-auto">
            <div className="row">
              <div className="col-md-11 offset-1 ms-0">
                <div className="section-title-left text-center text-md-start">
                  <h2 className="display-6">{quickReference.title}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Items  */}
        <div className="row">
          {quickReference.items?.map((data) => (
            <div
              className="col-md-6 col-lg-3 mb-4 mb-md-0"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <PageLink to={data.link}>
                <div className="achieve3__item h-100 translateEffect1">
                  <h3 className="m-15px-b">{data.title}</h3>
                  <p>{data.description}</p>
                  <img
                      src={data.preview}
                      className="img-fluid"
                      width="240"
                      border="1px solid"
                      background="#fff"
                    />
                </div>
              </PageLink>
            </div>
          ))}
        </div>
    </div>

    {/* Lesson Plans */}
    <div className="container" style={{ marginBottom: '12em' }}>
        {/* Title */}
        <div className="row">
          <div className="col-lg-6 my-auto">
            <div className="row">
              <div className="col-md-11 offset-1 ms-0">
                <div className="section-title-left text-center text-md-start">
                  <h2 className="display-6">{lessonPlans.title}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Items  */}
        <div className="row">
          {lessonPlans.items?.map((data) => (
            <div
              className="col-md-6 col-lg-3 mb-4 mb-md-0"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <PageLink to={data.link}>
                <div className="achieve3__item h-100 translateEffect1">
                  <h3 className="m-15px-b">{data.title}</h3>
                  <p>{data.description}</p>
                  <img
                      src={data.preview}
                      className="img-fluid"
                      width="240"
                      border="1px solid"
                      background="#fff"
                    />
                </div>
              </PageLink>
            </div>
          ))}
        </div>
    </div>

    {/* Slide Decks */}
    <div className="container" style={{ marginBottom: '12em' }}>
        {/* Title */}
        <div className="row">
          <div className="col-lg-6 my-auto">
            <div className="row">
              <div className="col-md-11 offset-1 ms-0">
                <div className="section-title-left text-center text-md-start">
                  <h2 className="display-6">{slideDecks.title}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Items  */}
        <div className="row">
          {slideDecks.items?.map((data) => (
            <div
              className="col-md-6 col-lg-3 mb-4 mb-md-0"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <PageLink to={data.link}>
                <div className="achieve3__item h-100 translateEffect1">
                  <h3 className="m-15px-b">{data.title}</h3>
                  <p>{data.description}</p>
                  <img
                      src={data.preview}
                      className="img-fluid"
                      width="240"
                      border="1px solid"
                      background="#fff"
                    />
                </div>
              </PageLink>
            </div>
          ))}
        </div>
    </div>
    
    {/* Writing Exercises */}
    <div id="writingexercises" className="container" style={{ marginBottom: '12em' }}>
        {/* Title */}
        <div className="row">
          <div className="col-lg-6 my-auto">
            <div className="row">
              <div className="col-md-11 offset-1 ms-0">
                <div className="section-title-left text-center text-md-start">
                  <h2 className="display-6">{writingExercises.title}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Items  */}
        <div className="row">
          {writingExercises.items?.map((data) => (
            <div
              className="col-md-6 col-lg-3 mb-4 mb-md-0"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <PageLink to={data.link}>
                <div className="achieve3__item h-100 translateEffect1">
                  <h3 className="m-15px-b">{data.title}</h3>
                  <p>{data.description}</p>
                  <img
                      src={data.preview}
                      className="img-fluid"
                      width="240"
                      border="1px solid"
                      background="#fff"
                    />
                </div>
              </PageLink>
            </div>
          ))}
        </div>
    </div>

    </section>
  );
};

export default Resourcesv2;
