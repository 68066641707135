import React from "react";
import Resources from "../resources";
import Chapterv2 from "../chapterv2";
import Footer from "../global/footer";
import Header from "../global/header";
import Hero from "../hero";
import Testimonialv2 from "../testimonialv2";

const Home = ({header, footer}) => {
  const {menu} = footer;

  return (
    <>
     <Header header={header} />
      <Hero isBg="yes" />
      <Testimonialv2 isBg="yes" />
      <Chapterv2 isBg="" />
      <Resources isBg="yes" />
      <Footer  isBg="yes" menu={menu} />
    </>
  );
}

export default Home;
