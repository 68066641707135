import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./assets/css/margins-paddings.css";
import Home from "./components/pages/home";
import ConfirmDownload from "./components/pages/confirm-download";
import AOS from "aos";
import { useEffect } from "react";
import "./assets/css/aos.css";
import "./assets/css/custom-style.css"
import Resources from "./components/pages/resources";
import StyleGuide from "./components/pages/style-guide";
import footerData from "./data/footer.json";
import headerData from "./data/header.json";
import NotFound from "./components/pages/no-match-404";

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  const { header } = headerData;
  const { footer } = footerData;
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className="section-wrapper">
      <BrowserRouter>
        <ScrollToTop/>
        <Routes>
          <Route exact path="/" element={<Home header={header} footer={footer} />} />
          <Route path="/downloads" element={<Resources header={header} footer={footer} />} />
          <Route path="/confirmdownload" element={<ConfirmDownload header={header} footer={footer} />} />
          <Route path="/style-guide" element={<StyleGuide header={header} footer={footer} />} />
          <Route path="/styleguide" element={<StyleGuide header={header} footer={footer} />} />
          <Route path="*" element={<NotFound header={header} footer={footer} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
